@import 'app.scss';
.breadcrumbs {
  --breadcrumb-fg-color: var(--hero-fg-color, --color-fg-primary);
}

.breadcrumb {
  display: flex;
  gap: spacing(1);
  align-items: center;
  color: var(--breadcrumb-fg-color);
}

.icon {
  width: 1rem;
}

@import 'app.scss';
.bar {
  @include typography-paragraph-md;

  padding: spacing(4) 0;

  &.brand {
    background-color: var(--color-bg-brand);
    color: var(--color-fg-brand);
  }

  &.primary {
    background-color: var(--color-bg-primary);
    color: var(--color-fg-primary);
  }

  &.secondary {
    background-color: var(--color-bg-secondary);
    color: var(--color-fg-secondary);
  }
}
